import { Pipe, PipeTransform } from '@angular/core';
import { RolesService } from '../../providers/roles/roles.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'requireRol',
  pure: true
})
export class RequireRolPipe implements PipeTransform {
  private rolChecked: boolean | boolean[] = null;
  private cachedRolChecked: any = '';

  constructor(private rolesService: RolesService) {}

  transform(value: string | string[], args?: string): boolean | boolean[] {
    if (value !== this.cachedRolChecked) {
      // console.log(value, args);
      if (Array.isArray(value)) {
        // TODO
        if (!args || args === 'and') {
          this.rolChecked = true;
        } else if (args === 'or') {
          this.rolChecked = false;
        }
        this.cachedRolChecked = value;
        for (const _rol of value) {
          const hasRol = this.requireRol(_rol);
          if (!hasRol && (!args || args === 'and')) {
            this.rolChecked = false;
          } else if (hasRol && args === 'or') {
            this.rolChecked = true;
          }
        }
      } else {
        this.rolChecked = null;
        this.cachedRolChecked = value;
        this.rolChecked = this.requireRol(value);
      }
    }
    return this.rolChecked;
  }

  private requireRol(value: string | string[]): boolean {
    return this.rolesService.requireRol(value);
  }
}
