import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-coderty-confirm-dialog',
  templateUrl: './coderty-confirm-dialog.component.html'
})
export class CodertyConfirmDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CodertyConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

}
