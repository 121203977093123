import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// Services
import { DatesUtilsService } from '../../../../shared/utils/dates-utils/dates-utils.service';
import { MatButtonToggle, MatButtonToggleChange } from '@angular/material';

@Component({
  selector: 'coderty-dates-ranges',
  templateUrl: './coderty-dates-ranges.component.html',
  styleUrls: ['./coderty-dates-ranges.component.sass']
})
export class CodertyDatesRangesComponent implements OnInit {
  @Input() validators: any;
  @Input() ranges: number[];
  @Input() layoutMode = 'row';
  @Input() defaultRange: number;

  @Output() onChangeDates = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private datesUtilsService: DatesUtilsService
  ) {}

  rangeButton;

  datesFG: FormGroup;
  isDay: boolean;
  isWeek: boolean;
  isMonth: boolean;
  isYear: boolean;
  private _today: Date = new Date();
  private _isManualDate = false;
  private _isRange = true;

  ngOnInit() {
    if (this.defaultRange) {
      this.rangeButton = this.defaultRange;
    } else if (this.ranges && this.ranges.length) {
      this.rangeButton = this.ranges[0];
    } else {
      this.rangeButton = 1;
    }
    this.rangeButton = this.rangeButton.toString();

    this.isDay = this.ranges.indexOf(1) >= 0 ? true : false;
    this.isWeek = this.ranges.indexOf(7) >= 0 ? true : false;
    this.isMonth = this.ranges.indexOf(30) >= 0 ? true : false;
    this.isYear = this.ranges.indexOf(365) >= 0 ? true : false;

    this.datesFG = this.fb.group({
      iniDate: [null, this.validators],
      endDate: [null, this.validators]
    });

    this.datesFG.valueChanges.subscribe(data => {
      if (this._isManualDate && !this._isRange) {
        this.rangeButton = null;
        this.onChangeDates.emit({
          datesValue: this.datesFG.value,
          formInvalid: this.datesFG.invalid
        });
      } else if (!this._isRange) {
        this._isManualDate = true;
        this.onChangeDates.emit({
          datesValue: this.datesFG.value,
          formInvalid: this.datesFG.invalid
        });
      } else {
        this._isRange = false;
      }
    });

    this.setDates(parseInt(this.rangeButton, 0));
  }

  next() {
    this._isManualDate = false;
    this._isRange = true;
    const res = this.datesUtilsService.nextFixed(
      this.datesFG.value.iniDate,
      parseInt(this.rangeButton, 0)
    );
    this.datesFG.controls.iniDate.setValue(res.initDate);
    this.datesFG.controls.endDate.setValue(res.endDate);
  }

  previous() {
    this._isManualDate = false;
    this._isRange = true;
    const res = this.datesUtilsService.previousFixed(
      this.datesFG.value.iniDate,
      parseInt(this.rangeButton, 0)
    );
    this.datesFG.controls.iniDate.setValue(res.initDate);
    this.datesFG.controls.endDate.setValue(res.endDate);
  }

  toggleChanged(event: MatButtonToggleChange) {
    this._isManualDate = false;
    this._isRange = true;
    this.rangeButton = event.value.toString();
    this.setDates(event.value);
  }

  private setDates(range) {
    switch (parseInt(range, 0)) {
      case 1:
        this.datesFG.controls.iniDate.setValue(this._today);
        break;
      case 7:
        this.datesFG.controls.iniDate.setValue(
          this.datesUtilsService.getFirstDayWeek()
        );
        break;
      case 30:
        this.datesFG.controls.iniDate.setValue(
          this.datesUtilsService.getFirstDayMonth()
        );
        break;
      case 365:
        this.datesFG.controls.iniDate.setValue(
          this.datesUtilsService.getFirstDayYear()
        );
        break;
    }
    this.datesFG.controls.endDate.setValue(this._today);
  }
}
