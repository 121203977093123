import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CodertyDatesRangesComponent } from './coderty-dates-ranges/coderty-dates-ranges.component';
import { DatesUtilsService } from '../../utils/dates-utils/dates-utils.service';
import { CodertyMaterialModule } from '../../../coderty-material/coderty-material.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CodertyMaterialModule],
  declarations: [CodertyDatesRangesComponent],
  exports: [CodertyDatesRangesComponent],
  providers: [DatesUtilsService]
})
export class CodertyDatesRangesModule {}
