import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';

// Interfaces
import { User } from './models/user.interface';
import { UserState } from './models/user-state.interface';
import { Profile } from '../profiles/models/profile.interface';
import { Subcentre } from './models/subcentre.interface';
import { Charge } from './models/charge.interface';
// Services
import { DatesUtilsService } from '../shared/utils/dates-utils/dates-utils.service';

const USERS_API = 'users/';
const USERS_API_V2 = `v2/${USERS_API}`;
const SUBCENTRES_API = 'subcentres/';

@Injectable()
export class UsersService {
  constructor(
    private httpClient: HttpClient,
    private datesUtilsService: DatesUtilsService
  ) {}

  /**
   * @param state Returns list of users of a state.
   * By default the active users
   * If state is null it will return all the users
   */
  getUsers(state: string = 'A'): Observable<User[]> {
    let url = `${environment.API_ENDPOINT}${USERS_API}`;
    url += state ? `state/${state}` : ``;
    return this.httpClient.get<User[]>(url);
  }

  /**
   * Returns a user
   * @param userId
   */
  getUser(userId: number): Observable<User[]> {
    return this.httpClient
      .get(`${environment.API_ENDPOINT}${USERS_API}${userId}`)
      .pipe(map((response: User) => response[0]));
  }

  /**
   * Returns the states of the users
   */
  getUserStates(): Observable<UserState[]> {
    return this.httpClient.get<UserState[]>(
      `${environment.API_ENDPOINT}${USERS_API}states/`
    );
  }

  /**
   * Returns all roles groups by profile and witch of them are actives for the user given
   * @param {number} userId
   */
  getProfileRol(userId: number): Observable<Profile[]> {
    return this.httpClient.get<Profile[]>(
      `${environment.API_ENDPOINT}${USERS_API_V2}${userId}/profilerol`
    );
  }

  /**
   * Returns the profiles and the roles of a charge
   * @param {string} chargeId
   */
  getChargeProfilesRoles(chargeId: string): Observable<Profile[]> {
    // TODO:: ¿Qué pasa si no le pasamos cargo? se puede dar ese caso?
    return this.httpClient.get<Profile[]>(
      `${environment.API_ENDPOINT}${USERS_API_V2}profilerol/${chargeId}`
    );
  }

  /**
   * Return the subcenters
   */
  getSubcentres(): Observable<Subcentre[]> {
    return this.httpClient.get<Subcentre[]>(
      `${environment.API_ENDPOINT}${SUBCENTRES_API}`
    );
  }

  /**
   * Returns the active charges
   */
  getCharges(): Observable<Charge[]> {
    return this.httpClient.get<Charge[]>(
      `${environment.API_ENDPOINT}${USERS_API}charges/`
    );
  }

  /**
   * Crea un usuario haciendo uso de la api de usuarios/
   * @param {User} userData
   * @param {any} userRoles
   * @param {any} passwords
   */
  saveUser( userData: User, userRoles: any, passwords: any ) {
    const profiles = this.getProfiles( userRoles );
    const birthday = this.getFormatDate( userData.birthday);
    const dataUser = this.returnDataUser( userData, birthday, passwords, profiles);

    return this.httpClient.post(`${environment.API_ENDPOINT}${USERS_API_V2}`, dataUser);
  }

  /**
   * Modifica los datos de un usuario concreto haciendo uso de la
   * api de usuarios/:id
   * @param {User} userData
   * @param {any} userRoles
   * @param {any} passwords
   */
  modifyUser( userData: User, userRoles: any, passwords: any ) {
    const userId = userData.userId;
    const profiles = this.getProfiles( userRoles );
    const birthday = this.getFormatDate( userData.birthday );
    const dataUser = this.returnDataUser( userData, birthday, passwords, profiles);

    return this.httpClient.post(`${environment.API_ENDPOINT}${USERS_API_V2}${userId}`, dataUser);
  }

  /**
   * Recorre el tipo de perfil del usuario e incluye todos sus roles
   * en un array
   * @param {any} userRoles
   * @return {any} profiles[id, roles]
   */
  getProfiles( userRoles: any ): any {
    // CHAPOTASO HASTA QUE SE ARREGLE LA API
    const profiles = [];
    for (const profile in userRoles) {
      if (userRoles.hasOwnProperty(profile)) {
        const roles = [];
        for (const rol in userRoles[profile]) {
          if (userRoles[profile].hasOwnProperty(rol)) {
            roles.push({
              rolId: rol,
              active: userRoles[profile][rol] ? 1 : 0
            });
          }
        }
        profiles.push({ profileId: profile, roles: roles });
      }
    }
    return profiles;
  }

  /**
   * Devuelve la fecha con formato yyyyMMdd para que en la consulta
   * de la DB sea procesada con str_to_date()
   * @param {Date} birthday
   * @return {any} formatBirthday
   */
  getFormatDate( birthday: Date ): any {
    let formatBirthday = null;
    formatBirthday = birthday
      ? this.datesUtilsService.formatDateToyyyyMMdd(
          new Date(birthday)
        )
      : '';
    return formatBirthday;
  }

  /**
   * Rellena todos los campos de Usuario de la DB para poder
   * enviarlos por POST
   * @param {User} userData
   * @param {Date} birthday
   * @param {any} passwords
   * @param {any} profiles
   * @return {any} data
   */
  returnDataUser( userData: User, birthday: Date, passwords: any, profiles: any ): any {
    const data = {
        user: userData.user,
        password: passwords ? Md5.hashStr(passwords.password) : null,
        groupId: userData.groupId,
        stateId: userData.stateId,
        name: userData.name,
        surnames: userData.surnames,
        nif: userData.nif,
        email: userData.email,
        birthday: birthday,
        phoneFixed: userData.phoneFixed,
        phoneExt: userData.phoneExt,
        phoneMobile: userData.phoneMobile,
        phonePersonal: userData.phonePersonal,
        profiles: profiles,
        subcentreId: userData.subcentreId,
        photo: userData.photo,
        chargeId: userData.chargeId
    };
    return data;
  }

  /**
   * Send email with url to recover password if the email is of an active user
   * @param { string } email
   */
  mailRecovery(email: string) {
    return this.httpClient.post(
      `${environment.API_ENDPOINT}${USERS_API}access/sendresetpass`,
      {
        email: email
      }
    );
  }
}
