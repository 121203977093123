import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { CodertyMaterialModule } from 'app/coderty-core-front/coderty-material/coderty-material.module';
// Components
import { CodertyAlertDialogComponent } from './coderty-alert-dialog/coderty-alert-dialog.component';

@NgModule({
  imports: [CommonModule, CodertyMaterialModule],
  declarations: [CodertyAlertDialogComponent],
  exports: [CodertyAlertDialogComponent],
  entryComponents: [CodertyAlertDialogComponent]
})
export class CodertyAlertDialogModule {}
