import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// Interfaces
import { Group } from './models/group.interface';
import { GroupsList } from './models/groups-list.interface';
import { GroupState } from './models/group-state.interface';

const GROUPS_API = `${environment.API_ENDPOINT}groups`;

@Injectable()
export class GroupsService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Return a list of groups filtered by a state
   * @param state State of the groups. Default returns groups with state A(active)
   * @return {Observable<GroupsList[]>}
   */
  getGroups(state: string = 'A'): Observable<GroupsList[]> {
    return this.http
      .get<GroupsList[]>(`${GROUPS_API}/state/${state}`);
  }

  /**
   * Return the posible states of a group
   * @return {Observable<GroupState[]>}
   */
  getGroupStates(): Observable<GroupState[]> {
    return this.http
      .get<GroupState[]>(`${GROUPS_API}/states`);
  }

  /**
   * Create or update a group
   * @param {Group} group
   * return {Observable<any>}
   */
  saveGroup(group: Group): Observable<any> {
    const groupId = group.id ? group.id : '';
    return this.http
      .post(`${GROUPS_API}/${groupId}`, {
        name: group.name,
        stateId: group.state
      });
  }

}
