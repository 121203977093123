import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequireRolPipe } from '../../pipes/require-rol/require-rol.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    RequireRolPipe
  ],
  exports: [
    RequireRolPipe
  ]
})
export class CodertyRequireRolModule { }
