import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { CodertyConfirmDialogComponent } from './coderty-confirm-dialog/coderty-confirm-dialog.component';
import { CodertyMaterialModule } from 'app/coderty-core-front/coderty-material/coderty-material.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, CodertyMaterialModule],
  declarations: [CodertyConfirmDialogComponent],
  exports: [CodertyConfirmDialogComponent],
  entryComponents: [CodertyConfirmDialogComponent]
})
export class CodertyConfirmDialogModule {}
