import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DownloadPdfService {

  constructor() { }

  downloadPdfBase64(b64Data, fileName: string) {
    const blob = this.base64ToBlob(b64Data, 'data:application/pdf;base64,');
    this.downloadPdfBlob(blob, fileName);
  }

  downloadPdfBuffer(bufferData, fileName: string) {
    const blob = this.bufferToBlob(bufferData, 'data:application/pdf;base64,');
    this.downloadPdfBlob(blob, fileName);
  }

  downloadPdfBlob(blob, fileName: string) {
    FileSaver.saveAs(blob, `${fileName || 'export'}.pdf`);
  }

  base64ToBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  bufferToBlob(bufferData, contentType, sliceSize?) {
    let binary = '';
    const bytes = new Uint8Array(bufferData);

    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    const blob = this.base64ToBlob(binary, contentType, sliceSize);
    return blob;
  }
}
